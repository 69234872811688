import React, { useState, useEffect, useRef, useReducer, FC } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import AutoPageTemplate from "./AutoPageTemplate";
import Space from "../sections/Space";
import { table } from "console";
import { Col, List, Row } from "antd";
import DomaTableComponent, { ICategoryTable, ICategoryTableItem } from "../components/DomaTableComponent";
import { getCategoryIdByCode, getProductsByCategoryIds } from "../Helper";
import { ICategoryProduct, IProductItem, IReportReturnModel } from "../Model";

const ADDITIONAL_TOP_SPACE = 48;

const PAGE_HEIGHT = 1123;
const HEADER_HEIGHT = 180;
const FOOTER_HEIGHT = 40;
const PADDINGS_HEIGHT = 28 * 2;
const FREE_SPACE = 16; // Dodatkowa przestrzeń na stronie pusta aby nie było treści do samej stopki
const PAGE_CONTENT_HEIGHT =
  PAGE_HEIGHT - HEADER_HEIGHT - FOOTER_HEIGHT - PADDINGS_HEIGHT - FREE_SPACE - ADDITIONAL_TOP_SPACE;

export const getDataProducts = (products: any[], vat: any) => {
  const data = [];
  for (const p of products) {
    const nettValue = Number(p.sumPrice.toFixed(2));
    const grossvalue = nettValue * (1 + vat / 100);
    data.push({
      id: p.id,
      productName: p.name,
      amount: p.amount.toFixed(2),
      priceUnit: p.unitPrice.toFixed(2),
      priceSum: nettValue.toFixed(2),
      priceGross: grossvalue.toFixed(2),
    });
  }

  return data;
};

interface TableItemDetails {
  content: string;
  height: number;
}

interface TableDetails {
  tableHeight: number;
  title: TableItemDetails;
  header: TableItemDetails;
  contentHeight: number;
  items: TableItemDetails[];
}

// Komponent opakowujący stronę dynamicznego raportu
// Tu wyciągamy wszystkie komponenty i informacje o nich
const ContentAutoPages = (props: any) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const calculateFullHeight = (element: Element): number => {
        const style = window.getComputedStyle(element);
        return element.clientHeight + parseFloat(style.marginTop) + parseFloat(style.marginBottom);
      };

      const gatherTableData = (table: Element): TableDetails => {
        const title = table.querySelector("[data-role='doma-table-title']");
        const header = table.querySelector("[data-role='doma-table-header']");
        const content = table.querySelector("[data-role='doma-table-content']");
        const items = content ? content.querySelectorAll("[data-role='doma-table-item']") : [];

        return {
          tableHeight: calculateFullHeight(table),
          title: {
            content: title ? title.outerHTML : "", // Zmienione na outerHTML
            height: title ? calculateFullHeight(title) : 0,
          },
          header: {
            content: header ? header.outerHTML : "", // Zmienione na outerHTML
            height: header ? calculateFullHeight(header) : 0,
          },
          contentHeight: content ? calculateFullHeight(content) : 0,
          items: Array.from(items).map((item) => ({
            content: item.outerHTML, // Zmienione na outerHTML
            height: calculateFullHeight(item),
          })),
        };
      };

      if (ref.current) {
        const tables: TableDetails[] = Array.from(ref.current.children)
          .map((child) => {
            if (child instanceof HTMLElement) {
              return gatherTableData(child);
            }
            return null; // or handle non-HTMLElement children if necessary
          })
          .filter((table): table is TableDetails => table !== null);

        props.contentAutoPagesChildren(tables);
      }
    }, 500); // Opóźnienie np. o 500ms

    return () => clearTimeout(timeoutId);
  }, [ref]);

  return <div ref={ref}>{props.children}</div>;
};

interface IPage {
  id: string;
  components: any[];
  sumHeight: number;
}

const initialPage = () => {
  const initialPage: IPage = {
    id: uuidv4(),
    components: [],
    sumHeight: 0,
  };

  return initialPage;
};

type Props = {
  report: IReportReturnModel;
  short: boolean;
};

const AutoPages: FC<Props> = ({ report, short }) => {
  const ref = useRef<HTMLDivElement>(null);

  const [components, setComponents] = useState<TableDetails[]>([]);
  const [pages, setPages] = useState<IPage[]>([]);

  // Tu dzielimy komponenty na strony
  useEffect(() => {
    if (components.length > 0) {
      // Ukrywamy robocze dane czyli wszystkie tabele -> będa wygenerowane z podziałem na strony
      if (ref.current) {
        ref.current.style.display = "none";
      }

      const pageList: IPage[] = []; // Lista stron do wygenerowania
      let page = initialPage(); // inicjowanie pustej strony ---- initialPage: any = {id: uuidv4(), components: [], sumHeight: 0};

      let tempHeight = PAGE_CONTENT_HEIGHT; // początkowo zawiera pełną wysokośc strony

      // Zmięnna która przechowa informację, że na nowej stronie trzeba dokończyć wyświetlanie tabeli
      let isNextPage = false;
      // Zmienna pokazująca ile już elementów zostało wyświeltonych na stronie
      let renderedItemsCount = 0;

      const marginTop = 16; // Margines między tabelami

      // Ile razy rozpoczyna się na tej stronie nowa tabela
      let startTableOnPageCount = 0; // potrzebne aby wyłapać ile razy margin uwzględnić

      // Pętla po wszystkich tabelach
      for (let index = 0; index < components.length; ) {
        const elm = components[index];

        const titleHeight = elm.title.height;
        const headerHeight = elm.header.height;
        const topHeight = titleHeight + headerHeight;
        const itemHeight = elm?.items[0]?.height || 0; // Wysokośc 1 elementu w tabeli

        // Gdy tabela nie zawiera elementów to przejdź dalej
        // TODO gdy ma być tez pusta tabela !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        // if (elm.items.length === 0) {
        //   index++;
        //   continue;
        // }

        // Gdy pusta tabela to dodajemy nagłówkei tabeli ale nie będzie zawartości
        let isEmptyTable = false;
        if (elm.items.length === 0) {
          isEmptyTable = true;
        }

        // 0. Sprawdzamy czy to jest kontynuacja tabeli z poprzedniej strony
        if (isNextPage && isEmptyTable === false) {
          // Sprawdxzamy ile elementów pozostało do wyświetlenia
          const remainingItemsCount = elm.items.length - renderedItemsCount;

          // Łączna wysokość pozostałych elementów
          const remainingItemsHeight = remainingItemsCount * itemHeight;

          // Sprawdzamy czy pozostałe elementy zmieszczą się na stronie
          if (remainingItemsHeight <= tempHeight) {
            // Jeśli tak to dodajemy do strony pozostałe elementy - juz bez tytułu i headera

            page.components.push(
              <DomaTable data-role="doma-table" key={index}>
                <DomaTableContent>
                  {elm.items
                    .slice(renderedItemsCount, renderedItemsCount + remainingItemsHeight)
                    .map((item, i) => (
                      <div key={i} dangerouslySetInnerHTML={{ __html: item.content }} />
                    ))}
                </DomaTableContent>
              </DomaTable>
            );

            // Zapamiętaj wysokość elementów na stronie
            tempHeight -= elm.items.slice(renderedItemsCount).reduce((acc, item) => acc + item.height, 0);

            isNextPage = false;
            renderedItemsCount = 0; // Dodajemy bo itemy mogą rzechodzić przez kilka strn

            if (index === components.length - 1) {
              page.sumHeight = PAGE_CONTENT_HEIGHT - tempHeight; // Zapamiętaj całkowitą wysokość komponentów na stronie
              pageList.push({ ...page });
              break;
            }

            index++; // Zwiększamy index bo przeszliśmy do kolejnej tabeli
            continue; // Przechodzimy do kolejnej tabeli
          } else {
            // Jeśli nie to dodajemy do strony tyle elementów ile zmieści się na stronie
            const freeHeight = tempHeight;
            // Zaokraglić w dół
            const items = Math.floor(freeHeight / itemHeight); // Obliczamy ilość elementów i zaokraglamy w dół
            // Dodajemy do strony tyle elementów ile zmieści się na stronie
            page.components.push(
              <DomaTable data-role="doma-table" key={index}>
                <DomaTableContent>
                  {elm.items.slice(renderedItemsCount, renderedItemsCount + items).map((item, i) => (
                    <div key={i} dangerouslySetInnerHTML={{ __html: item.content }} />
                  ))}
                </DomaTableContent>
              </DomaTable>
            );
            tempHeight -= elm.items.slice(renderedItemsCount, items).reduce((acc, item) => acc + item.height, 0);

            renderedItemsCount += items; // Dodajemy bo itemy mogą rzechodzić przez kilka strn

            // Zamykamy stronę i przechodzimy do nowej bo nie zmieści się cała tabela
            page.sumHeight = PAGE_CONTENT_HEIGHT - tempHeight; // Zapamiętaj całkowitą wysokość komponentów na stronie
            pageList.push({ ...page });
            page = initialPage();
            tempHeight = PAGE_CONTENT_HEIGHT;

            // index++; // Nie zwiększamy bo trzeba powtórzyc tabelę aby dokończyc
            continue; // Przechodzimy do kolejnej tabeli
          }
        }

        // 1. Sprawdzamy czy tabela zmieści się na stronie (dostepnej wysokości na obecnej stronie)
        const tableHeight = elm.tableHeight;
        if (tableHeight <= tempHeight) {
          // 2. Jeśli tak, to dodajemy ją w całości do strony
          page.components.push(
            <DomaTable data-role="doma-table" key={index}>
              <div dangerouslySetInnerHTML={{ __html: elm.title.content }} />
              <div dangerouslySetInnerHTML={{ __html: elm.header.content }} />
              <DomaTableContent>
                {elm.items.map((item, i) => (
                  <div key={i} dangerouslySetInnerHTML={{ __html: item.content }} />
                ))}
              </DomaTableContent>
            </DomaTable>
          );
          tempHeight -= tableHeight;

          // Czy to była ostatnia tabela - jesli tak to zamykamy stronę
          if (index === components.length - 1) {
            pageList.push({ ...page });
            break;
          }

          // Zmieściła się, więc przechodzimy do kolejnej tabeli
          startTableOnPageCount++; // Dodajmy start tabeli na tej stronie
          index++;
          continue; // Przechodzimy od razu do kolejnej tabeli
        }
        // ******************************************************************************************************************************

        // Jeśli nie zmieści w całości się
        if (tableHeight > tempHeight) {
          // Sprawdzamy czy zmieści się górna część tabeli wraz z 1 elementem z kolejnej tabeli (jeśli jest jakaś)
          // wysokośc górnej czesci tabeli z 1 item
          const topHeightWithoutItem = topHeight + startTableOnPageCount * marginTop; // wraz z marginesem jesli był
          const topHeightWithItem = topHeight + itemHeight + startTableOnPageCount * marginTop; // wraz z marginesem jesli był

          if (topHeightWithItem <= tempHeight) {
            // Jeśli tak to sprawdzamy ile dokładnie wierszy tej tabeli zmieści się na stronie
            const freeHeight = tempHeight - topHeightWithoutItem; // Wolna przestrzeń - cały nagłowek bez item
            // Zaokraglić w dół
            const items = Math.floor(freeHeight / itemHeight); // Obliczamy ilość elementów i zaokraglamy w dół

            // Dodajemy do strony tyle elementów ile zmieści się na stronie
            page.components.push(
              <DomaTable data-role="doma-table" key={index}>
                <div dangerouslySetInnerHTML={{ __html: elm.title.content }} />
                <div dangerouslySetInnerHTML={{ __html: elm.header.content }} />
                <DomaTableContent>
                  {elm.items.slice(0, items).map((item, i) => (
                    <div key={i} dangerouslySetInnerHTML={{ __html: item.content }} />
                  ))}
                </DomaTableContent>
              </DomaTable>
            );

            // Tu trzeba zapamiętać, że tabela nie została cała wyświetlona i resztę trzeba wyświetlić na nowej stronie
            isNextPage = true;
            renderedItemsCount += items; // Dodajemy bo itemy mogą rzechodzić przez kilka strn

            // Przesuń wskaźnik na kolejną tabelę
            // Nie zmieści się nagłowek z 1 pozycją więc zamykamy stronę i przechodzimy do nowej
            pageList.push({ ...page });

            page = initialPage();
            startTableOnPageCount = 0;
            tempHeight = PAGE_CONTENT_HEIGHT;
            // index++; // Tu powtarzamy pętlę bo nie zakończyliśmy całej tabeli
            continue; // Powtarzamy pętlę
          } else {
            // Zamykamy stronę i przechodzimy do nowej bo nie zmieści się minimalna ilość

            pageList.push({ ...page });
            page = initialPage();
            startTableOnPageCount = 0;
            tempHeight = PAGE_CONTENT_HEIGHT;
            // index++; // Tu powtarzamy pętlę bo nie zakończyliśmy całej tabeli
            continue; // Powtarzamy pętlę
          }
        }

        // Gdy ostani element i dotarło tu to zamykamy stronę
        if (index === components.length - 1) {
          pageList.push({ ...page });
          break;
        }
      }

      setPages(pageList);
    } else {
    }
  }, [components]); // Zależność tylko od zmian w components

  // Redukcja do pojedynczego obiektu dla uproszczonego raportu
  const reducedCategoriesObject: ICategoryProduct = report.categoryProducts.reduce(
    (acc, curr) => {
      // Sumowanie cen
      acc.nettPrice += curr.nettPrice;
      acc.vatPrice += curr.vatPrice;
      acc.price += curr.price;

      // Łączenie list produktów
      acc.products = acc.products.concat(curr.products.sort((a, b) => b.amount - a.amount));

      return acc;
    },
    {
      id: 0, // Przykładowe użycie wartości numerycznej
      name: "Wszystkie produkty",
      nettPrice: 0,
      vatPrice: 0,
      price: 0,
      products: [] as IProductItem[],
    }
  );

  return (
    <PageContainer>
      <PageWrapper ref={ref}>
        <ContentAutoPages
          contentAutoPagesChildren={(elements: any[]) => {
            if (components.length === 0) {
              setComponents(elements);
            }
          }}
        >
          {short ? (
            <DomaTableComponent
              isFull={report.isFull}
              showCount={report.withItemCount}
              position={reducedCategoriesObject}
              marginTop={0}
              short={true}
            />
          ) : (
            report.categoryProducts.map((position, i) => {
              if (position.products.length === 0) {
                return null;
              }
              return (
                <DomaTableComponent
                  key={i}
                  isFull={report.isFull}
                  showCount={report.withItemCount}
                  position={position}
                  marginTop={32}
                  short={false}
                />
              );
            })
          )}
        </ContentAutoPages>
      </PageWrapper>
      <Pages>
        {pages?.map((page, i) => {
          return (
            <PagesContent key={i}>
              <AutoPageTemplate report={report} topSpace={ADDITIONAL_TOP_SPACE}>
                <PageSection>{page.components}</PageSection>
              </AutoPageTemplate>
              <Space />
            </PagesContent>
          );
        })}
      </Pages>
    </PageContainer>
  );
};
export default AutoPages;

const DomaTable = styled.div``;
const DomaTableTitle = styled.div`
  display: flex;
  justify-content: space-between;
  display: flex;
  padding: 16px 0px 16px 0px;
  font-weight: bold;
`;
const DomaTableHeader = styled.div`
  padding: 24px;
  font-weight: bold;
  color: #0555a0;
`;
const DomaTableContent = styled.div`
  border: 1px solid #00539e;
`;

const DomaTableItem = styled.div`
  padding: 24px;
`;

const PageContainer = styled.div`
  display: table;
  margin: 0 auto;
  width: 794px !important;
  height: 1123px !important;
  background: #ffffff;
`;

const PageWrapper = styled.div`
  padding: 28px 36px 28px 36px;
  height: calc(1123px - 56px) !important;
`;

const Pages = styled.div``;

const PagesContent = styled.div``;

const PageSection = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 16px;
`;
